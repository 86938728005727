export const TOP_CHILDREN =
[
    {
        id: 'top-turtle',
        label: 'Turtle',
        children: [

        ],
    },
    {
        id: 'top-guard',
        label: 'Guard',
        children: [
            {
                id: 'topclosedguard',
                label: 'Closed Guard',
                children: [],
                icons: ['LibraryBooks', 'OndemandVideo']
            },           
        ]
    },
    {
        id: 'top-scoringpositions',
        label: 'Pins/Scoring Positions',
        children: [
            
        ]
    },
]